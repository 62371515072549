import React from "react";
import {Link} from "react-router-dom";
import {Toaster} from "react-hot-toast";

const Menu = () => {

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
            <div className="container">
                <Toaster />
                <Link to="/" className="navbar-brand mb-1"><span style={{color: "#FFC107"}}>app</span>sparc, llc</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">&nbsp;</span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav">
                        {/*<li className="nav-item">*/}
                        {/*    <Link className="nav-link" to="/software">1031 Exchange Software</Link>*/}
                        {/*</li>*/}
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Menu;
